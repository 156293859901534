<template>
  <div class="container">
    <Header />

    <div class="main">
      <div class="reservation">
        <div class="list">
          <div class="list-item" v-for="(reservation, index) of reservations" :key="index">
            <div style="display: flex; flex-direction: row; align-items: center; justify-content: space-between; background-color: #FBFBFB; padding: 20px; border-bottom: 1px solid #dedede;">
              <div>
                <span>{{ `${$t('bookingNumber')}: ${reservation.booking_number}` }}</span>
              </div>

              <div :class="getClassName(reservation)">
                {{ `${$t('bookingStatus')}: ${$t(`reservationStatus.${getStatus(reservation)}`)}` }}
              </div>
            </div>
            <el-row type="flex" :gutter="20" style="margin-top: 20px; display: flex; align-items: stretch;">
              <el-col :span="4">
                <van-image height="140" lazy-load fit="cover" :src="reservation.hotel.image" />
              </el-col>
              <el-col :span="16">
                <div class="hotel-name">{{ reservation.hotel.name }}</div>
                <div class="breakfast" style="margin-top: 20px;">
                  <span>{{ $t('checkin') }}: {{ reservation.arrival | formatDate }}</span>
                  <span style="margin-left: 20px;">{{ $t('checkout') }}: {{ reservation.departure | formatDate }}</span>
                  <span style="margin-left: 20px;">{{ reservation.nights }}{{ $t('nights') }}</span>
                </div>
                <div class="breakfast" style="margin-top: 20px;">
                  <span>{{ $t('finalPrice') }}: </span>
                  <span class="rate">{{ reservation.currency_code }}{{ reservation.total_amount | formatNumber }}</span>
                </div>
              </el-col>
              <el-col :span="4" style="display: flex; flex-direction: column; align-items: center; justify-content: space-around; height: 140px;">
                <div>
                  <el-button
                    size="mini"
                    v-if="reservation.payment_status === 'Unpaid' && reservation.status !== 'Cancelled' && reservation.status !== 'No Show'"
                    type="warning"
                    style="width: 120px;"
                    @click="goPayment(reservation)"
                  >
                    {{ $t('payNow') }}
                  </el-button>
                </div>

                <div>
                  <el-button
                    size="mini"
                    v-if="reservation.status !== 'Cancelled'"
                    type="success"
                    style="width: 120px;"
                    @click="goInvoice(reservation)"
                  >
                    {{ $t('invoice') }}
                  </el-button>
                </div>

                <div
                  v-if="reservation.payment_status !== 'Refund' && reservation.status === 'Reserved'"
                >
                  <el-button
                    size="mini"
                    type="default"
                    style="width: 120px;"
                    @click="goService(reservation)"
                  >
                    {{ $t('modify') }}
                  </el-button>
                </div>

                <div>
                  <el-button
                    size="mini"
                    type="primary"
                    style="width: 120px;"
                    @click="goReservationDetail(reservation)"
                  >
                    {{ $t('showDetail') }}
                  </el-button>
                </div>
              </el-col>
            </el-row>

            <van-divider />
          </div>
        </div>

        <van-empty v-if="reservations.length < 1" image="search" :description="$t('no booking')" />

      </div>

      <div style="flex: 1;"></div>
      <Footer/>

    </div>

    <van-popup v-model="showService" closeable :style="{ width: '500px' }">
      <div style="padding: 2.5em 1.4em;">
        <div class="word">{{ $t('cancel notify') }}</div>
        <div class="word">
          <span>{{ $t('customer tel') }}: </span>
          <span class="important-text">+86-400-098-2996</span>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Notify } from 'vant'
import Header from '@/components/Header'
// import { formatDate } from '@/filters'

export default {
  name: 'ReservationList',
  components: {
    Header
  },
  data () {
    return {
      loading: false,
      reservations: [],
      showService: false
    }
  },
  computed: {

  },
  async mounted () {
    await this.loadReservation()
  },
  methods: {
    getClassName (reservation) {
      return {
        [this.getStatus(reservation)]: true
      }
    },
    getStatus (reservation) {
      if (reservation.payment_status === 'Unpaid') {
        if (reservation.status === 'Cancelled' || reservation.status === 'No Show') {
          return reservation.status
        }

        return reservation.payment_status
      }

      return reservation.status
    },
    async loadReservation () {
      try {
        const res = await this.$axios.get('/reservation')
        this.reservations = res.data
      } catch (e) {
        Notify(e.message)
      }
    },
    goPayment (reservation) {
      if (reservation.payment_method === 'NotSelected') {
        this.$router.push({
          name: 'Payment',
          params: { reservationId: reservation.id }
        })
      } else {
        this.$router.push({
          name: reservation.payment_method,
          params: { reservationId: reservation.id }
        })
      }
    },
    goReservationDetail (reservation) {
      this.$router.push({
        name: 'ReservationDetail',
        params: { reservationId: reservation.id }
      })
    },
    goInvoice (reservation) {
      this.$router.push({
        name: 'Invoice',
        params: { reservationId: reservation.id }
      })
    },
    goService () {
      this.showService = true
    }
  }
}
</script>

<style lang="less" scoped>
.main {
  padding-top: 64px;
  max-width: 1200px;
  margin: 0 auto;
}

.reservation {
  padding: 1rem;
}

.hotel-name {
  margin-top: .3rem;
  font-size: 1.2rem;
}

.room-type-name {
  margin-top: .3rem;
  font-size: 1rem;
  color: #2766ba;
}

.list-item {
  border-top: 2px solid #2766ba;
}

.breakfast {
  color: #5c5c5c;
  margin-top: .3rem;
}

.Cancelled {
  color: #999999;
}

.Unpaid {
  color: #f3ab0f;
}
</style>
